import React from 'react';
import './App.css';

function App() {
  const handleButtonClick = () => {
    window.location.href = 'https://loicknosal.com/appclip';
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to My App</h1>
        <p>
          Open this page on Safari on your iPhone to launch the App Clip.
        </p>
        <button onClick={handleButtonClick}>
          Launch App Clip
        </button>
      </header>
    </div>
  );
}

export default App;
